.navbar-toggle {
  float: left;
  //margin-left: 15px;
}

.navmenu {
  z-index: 1;
}

.canvas {
  position: relative;
  left: 0;
  z-index: 2;
  min-height: 100%;
  padding: 50px 0 0 0;
  background: $body-bg;
}

.navmenu-default{
  background: #333;
}

.navbar-brand{
  padding-left: 0;
}
.navbar-toggle{
  margin-left: 3px;
  margin-right: 0;
}
@media (min-width: 0) {
  .navbar-toggle {
    display: block; /* force showing the toggle */
  }
}

@media (min-width: 992px) {
  body {
    padding: 0;
  }
  .navbar {
    //right: auto;
    //background: none;
    border: none;
  }
  .canvas {
    padding: 0;
  }
}

.navbar-brand {
  font-size: 15px;
}

@media (max-width: 600px)
{  ul.nav.nav-pills.pull-right span {
    display: none;
  }
}