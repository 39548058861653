// @import 'compass';

@import "bootstrap-custom/variables";
@import 'bootstrap-custom/bootstrap';
@import 'bootstrap-custom/bootswatch';
@import 'nav-menu-reval';

@import 'font-awesome';
@import 'font';

@import 'jquery.datetimepicker';

@import 'base';

@import 'avatars';
@import 'contact';

// модальное окно
@import 'modal';

html, body { height: 100% }

.form-control[disabled] {
  background: transparent;
  cursor: default;
}
.form-control {
  border: 0;
  // width: auto;
  background: transparent;
  display: inline-block;
  background: transparent;
  color: #ffffff;
  line-height: 15px;
  position: relative;
  margin: 0;
  padding: 0;
  width: 0;
  // height: 40px;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  z-index: 2;
  height: 40px;
}

// поле с поиском
.search .form-control{
  padding: 0 15px 0 25px;
}

.form-control {
  border-radius:0;
  box-shadow: none;

  &[type="search"]:hover,
  &[type="search"]:active,
  &[type="search"]:focus {
    color: #ffffff;
    box-shadow: none;
  }
  &[type="search"]:active, &[type="search"]:focus {
    width: 192px;
    position: relative;
  }
}


