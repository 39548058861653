// Импортируем переменные bootstrap
@import 'bootstrap-custom/variables';

nav {
  background-color: darken($body-bg, 3%);
}

html {
  position: relative;
  min-height: 100%;
}

// body {
//   // margin-bottom: 60px;
//   //background:#ebebeb;
//   //background-image: url('../images/background.gif');
// }

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  // min-height: 50px;
  // background-color: $navbar-inverse-bg;
}
