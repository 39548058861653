article.col-xs-4, article.col-sm-3, article.col-md-2 {
  padding: 3px;
}
.thumbnail {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;

  &:hover {
    text-decoration: none;
  }

  & .caption {
    padding: 0;
  }
  & .caption p {
    padding: 3px;
  }
}
.over {
  overflow: hidden;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
}
.over .mask {
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  color: black;
  transition: 0.8s;

  &:hover { opacity: 1 }
}
.circular {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  }

.mask a { margin: 3px }
// блок с именем
.over .thumbnail {
  // отодвинем блок с именами от img
  & img { margin-bottom: 3px }
  & .caption {
    line-height: 1;
    overflow: hidden;
    font-size: 12px;
  }
}